import React, { useEffect, useState,useCallback } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Table } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';
import { IpBlockLists, BlockedIp, deleteIp } from "Services/Api"
import { useNavigate } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';


const IpBlockList = () => {
  const { t } = useTranslation();
  document.title = t("Blocked Ip List");
  const [blockList, setBlockList] = useState<BlockedIp[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const response = await IpBlockLists();
      if (Array.isArray(response)) {
        setBlockList(response);
      } else {
        console.error('Invalid API response format:', response);
      }
    } catch (error: any) {
      console.error('Error fetching data:', error);
      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.")
      }
    } finally {
      setLoading(false);
    }
  }, [navigate, setBlockList, setLoading]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  
  


  const deleteIP = async (id: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success ",
        cancelButton: "btn btn-danger me-2"
      },
      buttonsStyling: false
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You want to delete Ip?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete Ip!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    });

    if (result.isConfirmed) {
      try {
        const response: any = await deleteIp(id);
        if (response.status === "success") {
          await Swal.fire({
            title: "Success!",
            text: "Ip deleted successfully !!!",
            icon: "success"
          });
          setBlockList(blockList.filter((ip) => ip.id !== id));
        }
      } catch (error) {
        console.error('Error updating status:', error);
        await Swal.fire({
          title: "Error!",
          text: "Failed to delete Ip.",
          icon: "error"
        });
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      await Swal.fire({
        title: "Cancelled",
        text: "Cancelled.",
        icon: "error"
      });
    }
  };

  return (

    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Blocked Ip List")} pageTitle={t("Pages")} />
            <div className="d-flex align-items-end justify-content-end mb-4">

              <Link to="/ip-blocking">
                <button className="btn btn-primary">{t('Block IP')}</button>
              </Link>
            </div>

            <div className="table-responsive">
              <Table className="table-striped table-nowrap align-middle mb-0">
                <thead>
                  <tr className="text-center">
                    <th scope="col">{t('Blocked Ip')}</th>
                    <th scope="col">{t('Created At')}</th>
                    <th scope="col">{t('Updated At')}</th>
                    <th scope="col">{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    Array.from({ length: blockList.length || 3 }).map((_, index) => (
                      <tr key={index} className="text-center">
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                      </tr>
                    ))
                  ) : (
                    blockList && blockList.length > 0 ? (
                      blockList.map((list) => (
                        <tr className="text-center" key={list.id}>
                          <td>{list.ip}</td>
                          <td>{list.created_at}</td>
                          <td>{list.updated_at}</td>
                          <td>
                            <FeatherIcon
                              icon="trash-2"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                deleteIP(list?.id); fetchData();
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr><td colSpan={6} style={{ textAlign: "center", fontWeight: "bold" }}>
                        No Records Found
                      </td></tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </SkeletonTheme>
  );
};

export default IpBlockList;
