import React, { useEffect, useState,useCallback } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../Components/Common/BreadCrumb";
import { Table, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { fetchAdvanceConfigForAdmin } from "Services/Api"
import { useNavigate } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';


const Yourkeys = () => {
  const { t } = useTranslation();
  document.title = t("View Keys");
  const [admin, setAdmin] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [copySuccess, setCopySuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalHeaderTitle, setModalHeaderTitle] = useState("Key Details");
  const [selectedKey, setSelectedKey] = useState<string | null>(null);


  const handleCopyToClipboard = (content: any) => {
    navigator.clipboard.writeText(content)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };


  const handleToggleModal = () => {
    setShowModal(!showModal);
    setModalHeaderTitle("Key Details");
  };

  const handleViewKey = (key: string, keyType: string) => {
    setSelectedKey(key);
    handleToggleModal();
    setModalHeaderTitle(keyType);
  };



  const fetchData = useCallback(async () => {
    try {
      const storedResponse = sessionStorage.getItem("authUser");

      if (!storedResponse) {
        console.error("Response data not found");
        return;
      }

      const parsedResponse = JSON.parse(storedResponse);
      const { operator_id } = parsedResponse.data;

      const apiResponse = await fetchAdvanceConfigForAdmin(operator_id);

      if (Array.isArray(apiResponse.data)) {
        setAdmin(apiResponse.data);
      } else {
        console.error("Invalid API response format:", apiResponse);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      if (error?.message === "Request failed with status code 401") {
        navigate("/login");
        toast.error("Token expired. Please login again.");
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);



  return (
    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Your Api Keys")} pageTitle={t("Pages")} />

            <div className="table-responsive">

              <Table className="table-striped table-nowrap align-middle mb-0">
                <thead>
                  <tr className="text-center">
                  <th scope="col">{t('Game Provider')}</th>
                    <th scope="col">{t('Secret Key')}</th>
                    <th scope="col">{t('Api Key')}</th>
                 
                  

                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    Array.from({ length: admin.length || 3 }).map((_, index) => (
                      <tr key={index} className="text-center">
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                      </tr>
                    ))
                  ) : (
                    admin && admin.length > 0 ? (
                      admin.map((admin: any) => (
                        <tr className="text-center" key={admin.id}>
                            <td>
                            {admin.vendor_name}
                            </td>
                          <td>
                            <span
                              onClick={() => handleViewKey(admin.secret_key, "Secret Key")}
                            >
                              <button className="btn btn-outline-success">
                                {t('View Secret Key')}
                              </button>
                            </span>
                          </td>
                          <td>
                            <span
                              onClick={() => handleViewKey(admin.api_key, "API Key")}
                            >
                              <button className="btn btn-outline-success">
                                {t('View API Key')}
                              </button>
                            </span>
                          </td>
                          {/* <td>{admin.token_expiry_date || "Expired"}</td> */}
                        
                        </tr>
                      ))
                    ) : (
                      <tr>No Data Available</tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
      </React.Fragment>
      <Modal isOpen={showModal} toggle={handleToggleModal} size="sm" style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={handleToggleModal}>
          {modalHeaderTitle}
        </ModalHeader>
        <ModalBody className="overflow-auto">{selectedKey}</ModalBody>
        <ModalFooter>

          <Button
            color={copySuccess ? "success" : "primary"}
            onClick={() => handleCopyToClipboard(selectedKey)}
          >
            {copySuccess ? 'Copied!' : 'Copy to Clipboard'}
          </Button>
          <Button color="primary" onClick={handleToggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </SkeletonTheme>
  );
};

export default Yourkeys;
