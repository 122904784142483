import React, { useState } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { Col, Card, CardBody, Input, Row, } from "reactstrap";
import { blockIP } from "Services/Api"
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const IpBlock = () => {
  const { t } = useTranslation();
  document.title = t("Block IP");
  const [ip, setIp] = useState<string>('');
  const navigate = useNavigate();


  const fetchData = async () => {
    try {
      const apiResponse = await blockIP(ip);

      if (apiResponse.status === "success") {
        toast.success(apiResponse.message);
        navigate('/ip-blocked-list')
      } else {
        console.log('error', apiResponse.errors);
      }
    } catch (error: any) {
      console.error('Error fetching data:', error);
      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.");
      } else {
        toast.error(`Error blocking IP: ${error.message}`);
      }
    }
  };

  return (
    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Ip-Blocking")} pageTitle={t("Pages")} />
            <div>
              <Row className="align-items-center">
                <Col lg={12}>
                  <div className="d-flex justify-content-end align-item-center">
                    <Link to="/ip-blocked-list"><button className="btn btn-primary"> <i className=" ri-arrow-left-line"></i>{t('Back')}</button></Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="d-flex align-items-end justify-content-center mt-3">
              <Col xs={12} md={12} lg={12} xxl={9} >
                <Card>
                  <CardBody>
                    <h4 className="card-title">{t('Block IP Address')}</h4>
                    <Row className="align-items-center border-dark mt-5">

                      <Col xl={6}>
                        <Input
                          type="text"
                          className="form-control"
                          id="domainInput"
                          onChange={(e) => setIp(e.target.value)}
                          value={ip}
                          placeholder={t("Enter IP Address")}
                        />
                      </Col>
                    </Row>
                    <div className="text-end mt-3">

                      <button className="btn btn-primary" onClick={() => { fetchData() }} >
                        {t('Block IP')}
                      </button>

                    </div>

                  </CardBody>
                </Card>
              </Col>
            </div>
          </Container>
        </div>
      </React.Fragment>

    </SkeletonTheme>
  );
};

export default IpBlock;
