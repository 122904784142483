import React, { useEffect, useState,useCallback } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Table } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';
import { fetchGameProvider, updateGameProviderStatus, Provider, BrandsGameList } from "Services/Api"
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PermissionHelper } from "Routes/helper";
import { useTranslation } from 'react-i18next';


const GameProvider = () => {
  const { t } = useTranslation();
  document.title = t('Game Provider List');
  const [provider, setProvider] = useState<Provider[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const checkPermission = PermissionHelper.PermissionChecker("game_provider.view");
      if (checkPermission) {
        const apiResponse = await fetchGameProvider();
  
        if (Array.isArray(apiResponse)) {
          setProvider(apiResponse);
        } else {
          console.error('Invalid API response format:', apiResponse);
        }
      } else {
        toast.error("You don't have permission to view this page");
      }
  
    } catch (error: any) {
      console.error('Error fetching data:', error);
      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.");
      } else {
        // Handle other errors if needed
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);  
  
  
  const handleIconClick = async (providerid: any, providerName: any) => {
    try {
      const requestData = { vendor_id: providerid };
      const response: any = await BrandsGameList(requestData);

      navigate(`/Edit-Provider/?vendor_id=${providerid}&name=${providerName}`, { state: { data: response } });

    } catch (error) {
      console.error('Error fetching item details:', error);
      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.")
      } else {
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getStatusText = (status: number) => {
    return status === 0 ?t( "InActive" ): t("Active");
  };

  const getStatusColor = (status: number) => {
    return status === 0 ? "badge bg-danger" : "badge bg-success";
  };

  const toggleStatus = async (id: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success ",
        cancelButton: "btn btn-danger me-2"
      },
      buttonsStyling: false
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You want to change status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change status!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    });

    if (result.isConfirmed) {
      try {
        const updatedProvider = provider.map((providerItem) => {
          if (providerItem.id === id) {
            return {
              ...providerItem,
              vendor_status: providerItem.vendor_status === 0 ? 1 : 0
            };
          }
          return providerItem;
        });

        setProvider(updatedProvider);

        const updatedStatus = updatedProvider.find((providerItem) => providerItem.id === id)?.vendor_status;

        if (updatedStatus !== undefined) {
          await updateGameProviderStatus(id, updatedStatus);

          await Swal.fire({
            title: "Status Changed!",
            text: "Provider status has been updated.",
            icon: "success"
          });
        } else {
          throw new Error('Failed to find updated status');
        }
      } catch (error) {
        console.error('Error updating status:', error);
        await Swal.fire({
          title: "Error!",
          text: "Failed to update provider status.",
          icon: "error"
        });
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      await Swal.fire({
        title: "Cancelled",
        text: "Cancelled.",
        icon: "error"
      });
    }
  };


  return (

    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Game Provider List")} pageTitle={t("Provider")} />

            <div className="table-responsive">
              <Table className="table-striped table-nowrap align-middle mb-0">
                <thead>
                  <tr className="text-center">
                    <th scope="col">{t('Game Provider Name')}</th>
                    <th scope="col">{t('Number Of Operators')}</th>
                    <th scope="col">{t('Rounds')}</th>
                    <th scope="col">{t('GGR')}</th>
                    <th scope="col">{t('Status')}</th>
                    <th scope="col">{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    Array.from({ length: provider.length || 3 }).map((_, index) => (
                      <tr key={index} className="text-center">
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                      </tr>
                    ))
                  ) : (
                    provider && provider.length > 0 ? (
                      provider.map((provider) => (
                        <tr className="text-center" key={provider.id}>
                          <td>{provider.vendor_name}</td>
                          <td>{provider.number_of_operators}</td>
                          <td>{provider.roundid_count}</td>
                          <td>{provider.ggr_amount} %</td>
                          <td>
                            <span
                              className={`rounded p-1 ${getStatusColor(provider.vendor_status)} text-white `}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (PermissionHelper.PermissionChecker("game_provider.status_change")) {
                                  toggleStatus(provider.id);
                                } else {
                                  toast.error("You don't have permission to change status");
                                }
                              }}
                            >
                              {getStatusText(provider.vendor_status)}
                            </span>
                          </td>
                          <td>
                            {PermissionHelper.PermissionChecker("game_provider.view") ? (
                              <Link to={`/Game-provider-detail/?name=${provider.vendor_name}&id=${provider.id}`}>
                                <FeatherIcon
                                  icon="eye"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    navigate(`/Game-provider-detail/?name=${provider.vendor_name}&id=${provider.id}`);
                                  }}
                                />
                              </Link>
                            ) : (
                              <FeatherIcon
                                icon="eye"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  toast.error('You do not have permission to view this provider.');
                                }}
                              />
                            )} |
                            {PermissionHelper.PermissionChecker("game_provider.edit") ? (
                              <Link to={"/Edit-Provider"}>
                                <FeatherIcon
                                 style={{ cursor: 'pointer' }}
                                  icon="edit-2"
                                  onClick={() => {
                                    handleIconClick(provider.id, provider.vendor_name);
                                  }}
                                />
                              </Link>
                            ) : (
                              <FeatherIcon
                                icon="edit-2"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  toast.error('You do not have permission to edit this provider.');
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr></tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </SkeletonTheme>
  );
};

export default GameProvider;
