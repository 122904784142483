import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../Components/Common/BreadCrumb";
import {  BASE_URL } from "Services/Api"
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';



const ViewDocumentation = () => {
  const { t } = useTranslation();
  document.title = t("View API Documents");
  return (
    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
          <BreadCrumb title={t("View Documents Of API")} pageTitle={t("Pages")} />
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>

              <a href={`${BASE_URL}/downloadpdf`} target="_blank" download rel="noopener noreferrer">
                <button className="mb-3 mt-0 btn btn-primary">
                {t('Download')} <FeatherIcon icon="download" />
                </button>
              </a>
            </div> 
            <div className="table-responsive">


              <iframe src={`${BASE_URL}/documentation`} title="Generated Keys" width="100%" height="800px"></iframe>


            </div>
          </Container>
        </div>
      </React.Fragment>

    </SkeletonTheme>
  );
};

export default ViewDocumentation;
